import shaka from 'shaka-player';

shaka.polyfill.installAll();

class DashBackground {
  constructor(element, opts={}) {
    let {
      sizes, aspectRatios = {},
      start, end, dur
    } = opts;

    this.element = element;
    this.sources = sizes || [];

    this.start = start || 0;
    this.end = end;
    this.dur = dur;
    if (end) this.dur = (end - this.start) * 1000;

    let { landscape, portrait } = aspectRatios;
    this.responsiveAspectRatio = landscape && portrait && landscape !== portrait;
    this.aspectRatios = aspectRatios;

    if (shaka.Player.isBrowserSupported()) {
      console.log('is supported');
      this.clearChildren();
      this.element.preload = 'auto';
      this.player = new shaka.Player(element);
      this.player.configure({
        abr: {
          restrictToElementSize: true, // needs ResizeObserver
          bandwidthUpgradeTarget: 0.6,
          bandwidthDowngradeTarget: 0.9,
        },
        streaming: {
          bufferBehind: 60,
          bufferingGoal: 4,
          rebufferingGoal: 2,
          stallEnabled: true,
          stallSkip: 0,
          stallThreshold: 0.1,
        }
      });

      let bufferTimeout;
      this.player.addEventListener('buffering', ({ buffering }) => {
        if (buffering) {
          bufferTimeout = setTimeout(() => this.restart(), 1200);
        } else if (bufferTimeout) {
          clearTimeout(bufferTimeout);
          bufferTimeout = null;
        }
      });
    } else {
      this.element.addEventListener('canplaythrough', () => {
        if (this.paused)
          this.element.play();
        if (this.dur)
          setInterval(this.restart.bind(this), this.dur);
      });
    }
    // set sources allows fallback if shaka player is not supported
    this.setSources();

    const bp = this.aspectRatios.breakpoint;
    window.addEventListener('resize', () => {
      let newOrientation = this.element.clientWidth / this.element.clientHeight;
      let oldOrientation = this.currentSource.ratio;
      if (newOrientation > bp && oldOrientation <= bp || newOrientation < bp && oldOrientation >= bp)
        this.setSources(this.bestSource());
    });
  }

  async setSources(source=this.bestSource()) {
    if (Object.is(source, this.currentSource))
      return null;

    if (this.player) {
      let { src } = source.formats[0];

      // iOS Safari doesn't handle dash well
      // in this case, start with HLS
      let hls = source.formats.find(s => s.type === 'application/x-mpegURL');
      if (hls && this.element.canPlayType('application/x-mpegURL')) {
        src = hls.src;
      }

      await this.player.load(src, this.start)
        .catch((e) => {
          if (e.code === 3016 && e.data[0] === 4)
            return this.useFallback(
              source.formats.filter(f => f.src !== src)
            );
        });

      if (this.element.paused)
        this.element.play();
    } else {
      await this.useFallback(source);
    }

    if (source.preview)
      this.element.poster = source.preview;
    this.currentSource = source;
  }

  bestSource() {
    let { landscape, portrait, breakpoint } = this.aspectRatios;
    let { clientWidth:w, clientHeight:h } = this.element;
    let largest;

    this.element.width = Math.ceil(w);
    this.element.height = Math.ceil(h);

    for (let opt of this.sources) {
      let { ratio } = opt;
      let orientation = !this.responsiveAspectRatio
        || ratio === landscape && w/h >= breakpoint
        || ratio === portrait && w/h < breakpoint;
      if (orientation)
        return opt;
    }

    return largest;
  }

  clearChildren(element=this.element) {
    while (element.firstChild)
      element.removeChild(element.firstChild);
  }

  async useFallback(formats) {
    for (let i = 0; i < formats.length; i++) {
      let { src, type } = formats[i];
      try {
        if (this.element.canPlayType(type)) {
          let playing = await this.player.load(src, this.start);
          console.log('using fallback', src);
          return playing;
        }
      } catch (e) {
        console.log('fallback failed');
        console.error(e);
        if (e.code === 3016)
          continue;
        else
          throw e;
      }
    }
    return null;
  }

  restart() {
    this.element.currentTime = this.start || 0;
  }
}

Array.from(document.querySelectorAll('[data-dash-bg]'))
  .map(e => new DashBackground(e, JSON.parse(e.dataset.dashBg)));
